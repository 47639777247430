<template>
  <div class="py-7 px-8">
    <header class="d-flex justify-space-between">
      <p>
        Add images to your gallery to show your customers what your restaurant
        looks like.
      </p>
      <FileExplorer
        :shop_id="shop_id"
        @select-image="(image) => add_image_to_collection(image)"
        label="Open storage"
        :square="false"
        :rectangle="false"
        :wide-rectangle="true"
      />
    </header>

    <template v-if="images.length === 0">
      <p class="text-center font-weight-bold">No images added yet.</p>
      <v-divider class="mt-3 py-3" />
    </template>
    <template v-else>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="(image, idx) in images" :key="image.id">
              <td class="py-2">
                <v-img
                  class="br-8"
                  :src="image.thumbnail_url"
                  max-width="120"
                />
              </td>
              <td>
                <v-btn icon large :disabled="idx === 0" @click="move_up(idx)"
                  ><v-icon>mdi-arrow-up-circle-outline</v-icon></v-btn
                >
                <v-btn
                  icon
                  large
                  :disabled="idx === images.length - 1"
                  @click="move_down(idx)"
                  ><v-icon>mdi-arrow-down-circle-outline</v-icon></v-btn
                >
                <v-btn icon large @click="delete_image(image.id)"
                  ><v-icon>mdi-delete-outline</v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </div>
</template>

<script>
import FileExplorer from "@/components/file_manager/FileExplorer";
import { mapState } from "vuex";
import {
  get_collection_images,
  add_image_to_collection as add_image_to_collection_request,
  move_collection_image_before_image as move_collection_image_before_image_request,
  move_collection_image_after_image as move_collection_image_after_image_request,
  delete_collection_image as delete_collection_image_request,
} from "@/requests";

export default {
  name: "BookingSettingsGallery",
  props: {},
  components: {
    FileExplorer,
  },
  data() {
    return {
      images: [],
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    ...mapState("TableBookingStore", {
      fetched_business_hours: "business_hours",
    }),
  },
  async mounted() {
    await this.get_images();
  },
  methods: {
    async get_images() {
      const { data: images } = await get_collection_images(
        this.shop_id,
        "booking_gallery"
      );
      this.images = images;
    },
    async add_image_to_collection(image) {
      try {
        await add_image_to_collection_request(
          image.id,
          "booking_gallery",
          this.shop_id
        );
        await this.get_images();
      } catch (error) {
        console.log(error);
      }
    },
    async move_up(idx) {
      const image = this.images[idx];
      const before_image = this.images[idx - 1];

      await move_collection_image_before_image_request(
        "booking_gallery",
        this.shop_id,
        image.id,
        before_image.id
      );
      await this.get_images();
    },
    async move_down(idx) {
      const image = this.images[idx];
      const after_image = this.images[idx + 1];

      await move_collection_image_after_image_request(
        "booking_gallery",
        this.shop_id,
        image.id,
        after_image.id
      );
      await this.get_images();
    },
    async delete_image(image_id) {
      await delete_collection_image_request(
        "booking_gallery",
        this.shop_id,
        image_id
      );
      await this.get_images();
    },
  },
};
</script>

<style lang="scss" scoped></style>
